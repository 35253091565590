import React from "react"
import Page from "../../layouts/Page";
import BlogCard from "../../components/BlogCard";
import IllustrationBlog from "../../media/illustration/Start.png"
import { graphql } from "gatsby";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { allMarkdownRemark } = data // data.markdownRemark holds your post data
    const { edges } = allMarkdownRemark
    return (
        <Page title="Blog Akimed" description="Des articles pour aider les acteurs de la e-santé.">
        <section className="section">
            <div className="container">
                <div class="columns">
                    <div class="column is-7">
                        {edges.map(e => (
                            <BlogCard 
                            title={e.node.frontmatter.title} 
                            description={e.node.frontmatter.description} 
                            url={e.node.frontmatter.slug} 
                            author={e.node.frontmatter.author} 
                            date={e.node.frontmatter.date} 
                            minutes={e.node.frontmatter.minutes} 
                            />
                        ))}
                    </div>
                    <div class="column is-4 is-offset-1">
                      <figure class="image">
                        <img src={IllustrationBlog} />
                      </figure>
                    </div>
                </div>
            </div>
        </section>
        </Page>
        )
    }
    
    export const pageQuery = graphql`
    query {
      allMarkdownRemark(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {frontmatter: {published: {eq: true}}}
      ) {
        edges {
          node {
            frontmatter {
              title
              slug
              published
              minutes
              date(formatString: "MMMM DD")
              author
              description
            }
          }
        }
      }
    }             
    `